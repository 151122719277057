
import Vue from "vue";

import UploadArea from "@/components/UploadArea/UploadArea.vue";
import * as selectOptions from "@/helpers/selectOptions";
import { downloadImportFileTemplate } from "@/helpers";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import { ImportType } from "@/store/modules/import/types";
import { get } from "lodash";
interface IData {
  message: string;
  error: string;
  value: string;
  uploadData: Record<string, any>;
  importTypeOptions: any;
}
type IUploadDialogMessage = {
  [key in ImportType]: string;
};

export default Vue.extend({
  name: "addNewUpload",
  components: {
    UploadArea,
    CustomAlert
  },
  props: {
    defaultType: {
      type: String,
      required: false,
      default: ""
    },
    showHeading: {
      type: Boolean,
      required: false,
      default: true
    },
    url: {
      type: String,
      required: false,
      default: "imports/upload"
    }
  },
  data(): IData {
    return {
      message: "",
      error: "",
      value: "",
      uploadData: {
        type: ""
      },
      importTypeOptions: selectOptions.importTypeOptions
    };
  },
  methods: {
    onFileUploadSuccess(result: any) {
      this.$appNotifySuccess("File Upload Success");
      this.$emit("upload-success", result);
      const importId = get(result, "response._id");
      if (importId) {
        if (this.$isCurrentUserAdmin) {
          this.$router.push(`/admin/imports/${importId}`);
        } else {
          this.$router.push(`/imports/${importId}`);
        }
      }
      this.$emit("close");
    },
    onFileUploadError(err: any) {
      this.error = err.response.message;
      this.$emit("error", err);
      // this.$emit("close");
    },
    importsTemplateDownloadHandler() {
      let type = "users";
      switch (this.uploadData.type) {
        case "mortgages":
          type = "mortgage";
          break;
        case "endorsements":
          type = "endorsement";
          break;
        case "underwriting-question":
          type = "underwritingQuestion";
          break;
        default:
          type = "users";
          break;
      }
      downloadImportFileTemplate(type);
    }
  },
  computed: {
    showUploader(): boolean {
      return this.defaultType.length > 0 || this.uploadData.type.length > 0;
    },
    uploadsData(): any {
      if (this.defaultType) return { type: this.defaultType };
      else return this.uploadData;
    },
    uploadMessage(): IUploadDialogMessage {
      return {
        users: this.__getText("global", "bulkUserImportMessage") as string,
        mortgages: this.__getText(
          "global",
          "bulkMortgageImportMessage"
        ) as string,
        endorsements: this.__getText(
          "global",
          "bulkEndorsementImportMessage"
        ) as string
      };
    }
  },
  watch: {
    error(errorMessage: string) {
      if (errorMessage.length > 0) {
        if (
          errorMessage.includes("Validation error") ||
          errorMessage.includes("Missing required parameter")
        )
          (this.$refs.uploadArea as any).fileList = [];
      }
    },
    defaultType: {
      handler(type: string) {
        this.uploadData.type = type;
      },
      immediate: true
    }
  }
});
